import { render, staticRenderFns } from "./SeptemberThomas.vue?vue&type=template&id=4c2dbdfe&scoped=true&"
import script from "./SeptemberThomas.vue?vue&type=script&lang=js&"
export * from "./SeptemberThomas.vue?vue&type=script&lang=js&"
import style0 from "./SeptemberThomas.vue?vue&type=style&index=0&id=4c2dbdfe&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c2dbdfe",
  null
  
)

export default component.exports