<template>
    <BContainer fluid>
        <h1 class="text-center">{{projects.slide1[1].projectName}}</h1>

        <BRow class="justify-content-center mt-4">
            <div class="col-12 col-xl-6">
                <BImg
                    class="image"
                    :src="projects.slide1[1].websiteImageUrl"
                    fluid
                />
            </div>

            <div class="col-12 col-xl-6">
                <h4 class="d-none d-md-block">
                    The Stack
                </h4>

                <div class="mt-md-0 mt-2 text-center text-md-left">
                    <b-badge class="vue-badge">
                        <FontAwesomeIcon
                        :icon="[ 'fab', 'vuejs' ]"
                        class="fa-2x"
                        />
                        Vue
                    </b-badge>

                    <b-badge class="ml-2 django-badge">
                        <FontAwesomeIcon
                        :icon="[ 'fab', 'python' ]"
                        class="fa-2x"
                        />
                        Django
                    </b-badge>

                    <b-badge class="ml-2 bootstrap-badge">
                        <FontAwesomeIcon
                        :icon="[ 'fab', 'bootstrap' ]"
                        class="fa-2x"
                        />
                        Bootstrap
                    </b-badge>

                    <b-badge class="ml-2 mt-2 mt-md-0 sass-badge">
                        <FontAwesomeIcon
                        :icon="[ 'fab', 'sass' ]"
                        class="fa-2x"
                        />
                        Sass
                    </b-badge>
                </div>

                <h4 class="d-none d-md-block mt-4">
                    About
                </h4>

                <p class="mt-md-0 mt-3">
                    This website is for the author, September Thomas. The goal of the website is to display her catalog of books and 
                    information about herself. It also includes a blog where she is able to share updates with her readers.
                </p>

                <p>
                    Developing this website was my first experience with shipping a finished product. I deployed it using Heroku, which 
                    was a rewarding learning experience for me. The domain is hosted and secured through DreamHost. The challenge here was 
                    getting Heroku and DreamHost to work together which I was happy to overcome. I learned that messing around with DNS 
                    settings was not a specialty but I could get it done. This all ended up being a very valuable experience, and I 
                    continue to make improvements to the website.
                </p>

                <p>
                    A future improvement will be to create a more functional admin for the author to use. She will be able to add books as 
                    well as update any book details or information on the site. Currently, the admin only allows for blog posts to be 
                    created, edited, and deleted.
                </p>

                <p>
                    The API for September Thomas is hosted separately, also on Heroku, and was written using Django.
                </p>
            </div>
        </BRow>

        <ProjectNav
            :next-project="'Quick Buck'"
            :previous-project="'Zauvu'"
            :website-url="projects.slide1[1].url"
        />
    </BContainer>
</template>

<script>
    import Vue from 'vue';
    import {mapState} from 'vuex';

    import ProjectNav from '@/components/ProjectNav';

    import {library} from "@fortawesome/fontawesome-svg-core";

    import {
        faBootstrap,
        faPython,
        faSass,
        faVuejs,
    } from '@fortawesome/free-brands-svg-icons';
    import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

    library.add(
        faBootstrap,
        faPython,
        faSass,
        faVuejs,
    );

    Vue.component('FontAwesomeIcon', FontAwesomeIcon);

    export default {
        name: 'SeptemberThomas',
        components: {
            ProjectNav,
        },
        computed: {
            ...mapState(['projects'])
        }
    }
</script>

<style
    lang="sass"
    scoped
>
    @import "/src/custom"

    .fa-4x
        color: $primary-green
        &:hover
            color: $secondary-green

    .image
        max-height: 100%
        max-width: 100%
        border-radius: 10px
</style>
